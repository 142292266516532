<template>
  <div class="finance-info" v-if="canLoad">
    <div class="title-data">
      <span>财务总览</span>
      <div class="detail" v-if="buttonList.includes('details')" @click="goAccountDetail">
        订单明细
      </div>
      <div class="detail" @click="openEarlyWarning">预警提醒</div>
    </div>
    <div class="version-info">
      <version-info ref="versionInfo"></version-info>
    </div>
    <div class="statistical-line">
      <div class="serach-line">
        <span>用量统计</span>
        <vh-tooltip effect="dark" placement="right" v-tooltipMove>
          <div slot="content" v-if="versionType">
            1.{{
              versionType == 1
                ? '数据更新频率：直播使用流量1小时更新，回放/点播使用流量1小时更新'
                : '数据更新频率为1小时'
            }}
            <br />
            2.删除活动或者删除子账号，不影响已统计的历史数据
          </div>
          <div slot="content" v-else>
            1.数据更新频率：天，不支持查看当日数据
            <br />
            2.并发只针对直播状态的活动，观看回放和点播时不消耗并发
            <br />
            3.删除活动或者删除子账号，不影响已统计的历史数据
          </div>
          <i class="iconfont-v3 saasicon_help_m"></i>
        </vh-tooltip>
        <div class="search-data">
          <vh-date-picker
            round
            v-model="lineSearchDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            @change="getLineList"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            prefix-icon="iconfont-v3 saasicon_date"
            style="width: 240px"
          />
          <vh-select
            round
            filterable
            v-model="lineType"
            style="width: 160px; marginleft: 15px"
            @change="getSearchLineList"
            v-if="type"
          >
            <vh-option
              v-for="(opt, optIndex) in versionList"
              :key="optIndex"
              :label="opt.label"
              :value="opt.value"
            />
          </vh-select>
          <div class="export-data">
            <vh-button plain round size="medium" @click="exportCenterData">导出数据</vh-button>
          </div>
        </div>
        <!-- <search-area
            ref="searchLineLayout"
            :isDate="isLineDate"
            :searchAreaLayout="searchLineLayout"
            @onExportData="exportCenterData()"
            @onSearchFun="getLineList()"
          >
          </search-area> -->
        <lint-charts :lineDataList="lintData" :type="parseInt(versionType)"></lint-charts>
      </div>
    </div>
    <div class="statistical-line">
      <div class="serach-line">
        <span>消费账单</span>
        <vh-tooltip effect="dark" placement="right" v-tooltipMove>
          <div slot="content" v-if="versionType == 1">
            数据更新频率：直播使用流量1小时更新，回放/点播使用流量1小时更新，短信消耗实时更新，
            <br />
            删除活动或者删除子账号，不影响已统计的历史数据
          </div>
          <div slot="content" v-if="versionType == 2">
            1.时长统计每小时更新一次
            <br />
            2.时长套餐按人/分钟进行时长消耗
            <br />
            3.删除活动或者删除子账号，不影响已统计的历史数据
            <br />
            4.时长消耗保留2位小数，累计/直播/回放使用时长为实际消耗，与列表中的时长存在误差，但扣费以实际消耗为准
          </div>
          <div slot="content" v-else>
            1.数据更新频率10分钟，建议活动结束10分钟后查看完整数据
            <br />
            2.并发只针对直播状态的活动，观看回放和点播时不消耗并发
            <br />
            3.删除活动或者删除子账号，不影响已统计的历史数据
          </div>
          <i class="iconfont-v3 saasicon_help_m"></i>
        </vh-tooltip>
        <div class="search-data">
          <vh-date-picker
            round
            v-model="accountSearchDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            prefix-icon="iconfont-v3 saasicon_date"
            @change="getSearchList"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptionsTwo"
            style="width: 240px"
          />
          <vh-input
            round
            v-model="subject"
            placeholder="请输入活动名称"
            class="search-tag"
            style="width: 220px; marginleft: 15px"
            @keyup.enter.native="getSearchList"
            maxlength="50"
            @clear="getSearchList"
            :clearable="true"
          >
            <i
              slot="prefix"
              class="vh-icon-search vh-input__icon"
              @click="getSearchList"
              style="cursor: pointer; line-height: 36px"
            ></i>
          </vh-input>
          <vh-select
            round
            filterable
            v-model="accountType"
            style="width: 160px; marginleft: 15px"
            @change="getTypeList"
            v-if="type"
          >
            <vh-option
              v-for="(opt, optIndex) in versionList"
              :key="optIndex"
              :label="opt.label"
              :value="opt.value"
            />
          </vh-select>
          <vh-select
            round
            filterable
            v-model="trendType"
            style="width: 160px; marginleft: 15px"
            @change="getTypeList"
            v-if="showSmsModule"
          >
            <vh-option
              v-for="(opt, optIndex) in [
                {
                  label: '套餐消费',
                  value: 'other'
                },
                {
                  label: '短信消费',
                  value: 'sms'
                }
              ]"
              :key="optIndex"
              :label="opt.label"
              :value="opt.value"
            />
          </vh-select>
          <div class="export-data">
            <vh-button plain round size="medium" @click="exportAccount">导出数据</vh-button>
          </div>
        </div>
        <div
          :class="[
            'content-grid',
            showSmsModule && smsInfo && smsInfo.sms >= 0 ? 'include_sms' : ''
          ]"
          v-if="!versionType"
        >
          <div class="grid-item">
            <div class="grid-content">
              <p>累计直播（个）</p>
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">筛选条件内创建的活动总数</div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
              <h1 class="custom-font-barlow">{{ trendData.webinar_num || 0 }}</h1>
            </div>
          </div>
          <div class="grid-item">
            <div class="grid-content">
              <p>最高并发（方）</p>
              <h1 class="custom-font-barlow">{{ trendData.max_uv || 0 }}</h1>
            </div>
          </div>
          <div class="grid-item" v-if="showSmsModule">
            <div class="grid-content">
              <p>短信消耗(条)</p>
              <h1 class="custom-font-barlow">{{ (smsInfo && smsInfo.sms) || 0 }}</h1>
            </div>
          </div>
        </div>
        <div :class="['content-grid', showSmsModule ? 'include_sms' : '']" v-else>
          <div class="content-item">
            <div class="grid-content">
              <p>累计活动（个）</p>
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  筛选条件内{{ versionType == 1 ? '创建的' : '' }}活动总数，包含直播+点播活动
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
              <h1 class="custom-font-barlow">{{ trendData.webinar_num || 0 }}</h1>
            </div>
          </div>
          <div class="content-item">
            <div class="grid-content">
              <p>{{ versionType == 1 ? '累计使用流量（GB）' : '累计使用时长（分钟）' }}</p>
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  {{
                    versionType == 1
                      ? '筛选条件内的直播使用流量+回放使用流量的总和'
                      : '筛选条件内的直播使用时长+回放使用时长的总和'
                  }}
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
              <h1 class="custom-font-barlow">
                {{ versionType == 1 ? trendData.total_flow || 0 : trendData.total_duration || 0 }}
              </h1>
            </div>
          </div>
          <div class="content-item">
            <div class="grid-content">
              <p>{{ versionType == 1 ? '直播使用流量（GB）' : '直播使用时长（分钟）' }}</p>
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  {{
                    versionType == 1
                      ? '筛选条件内的直播使用流量汇总，包含视频直播、互动直播、音频直播消耗的总流量'
                      : '筛选条件内的直播时长使用量的汇总，包含直播时长+角色观看时长+观看暖场时长'
                  }}
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
              <h1 class="custom-font-barlow">
                {{ versionType == 1 ? trendData.live_flow || 0 : trendData.live_duration || 0 }}
              </h1>
            </div>
          </div>
          <div class="content-item">
            <div class="grid-content">
              <p>{{ versionType == 1 ? '回放使用流量（GB）' : '回放使用时长（分钟）' }}</p>
              <vh-tooltip effect="dark" placement="right" v-tooltipMove>
                <div slot="content">
                  {{
                    versionType == 1
                      ? '筛选条件内的回放使用流量汇总，包含回放、点播消耗的总流量'
                      : '筛选条件内的回放时长使用量的汇总，包含回放、点播的总时长'
                  }}
                </div>
                <i class="iconfont-v3 saasicon_help_m"></i>
              </vh-tooltip>
              <h1 class="custom-font-barlow">
                {{ versionType == 1 ? trendData.vod_flow || 0 : trendData.vod_duration || 0 }}
              </h1>
            </div>
          </div>
          <div class="content-item" v-if="showSmsModule">
            <div class="grid-content">
              <p>短信消耗(条)</p>
              <h1 class="custom-font-barlow">{{ (smsInfo && smsInfo.sms) || 0 }}</h1>
            </div>
          </div>
        </div>
        <div class="list_info">
          <table-list
            ref="accountTableList"
            :manageTableData="trendType == 'sms' ? tableCmsList : tableList"
            :tabelColumnLabel="trendType == 'sms' ? tableCmsColumn : tableColumn"
            :isCheckout="false"
            :isHandle="false"
            :totalNum="trendType == 'sms' ? cmsTotalNum : totalNum"
            @getTableList="getAccountList"
          ></table-list>
          <noData
            :nullType="'nullData'"
            v-if="trendType == 'sms' ? !cmsTotalNum : !totalNum"
            :text="'暂无数据'"
          ></noData>
        </div>
      </div>
    </div>
    <!-- 下载弹窗 -->
    <vh-dialog
      title="预警提醒"
      :visible.sync="earlyWarningVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="720px"
      custom-class="earlyWarningSet"
    >
      <!-- <div class="title">为了不影响您的正常业务，请根据实际情况设置预警提醒。</div> -->

      <div class="formItem">
        <div class="label">预警开关</div>
        <div class="vals swtich">
          <vh-switch
            class="swtich"
            v-model="warning.alertSwitch"
            active-color="#FB3A32"
            inactive-color="#CECECE"
            :active-text="alertSwitchDesc"
          ></vh-switch>
        </div>
      </div>
      <div class="formItem">
        <div class="label">{{ resourceTypes[warning.type] }}设置</div>
        <div class="vals">
          <div class="inputs">
            <vh-input
              class="setInput"
              placeholder="请输入提醒数值"
              v-model.trim="warning.value"
              :disabled="!warning.alertSwitch"
              v-clearEmoij
            >
              <template slot="append" v-if="resourceTypesItem[warning.type]">
                {{ resourceTypesItem[warning.type] }}
              </template>
            </vh-input>

            <vh-input
              class="setInput"
              placeholder="请输入接收手机"
              v-model.trim="warning.phone"
              :disabled="!warning.alertSwitch"
              v-clearEmoij
            ></vh-input>
            <vh-input
              class="setInput"
              placeholder="请输入接收邮箱"
              v-model.trim="warning.email"
              :disabled="!warning.alertSwitch"
              v-clearEmoij
            ></vh-input>
          </div>
          <div class="tip">
            当{{
              resourceTypesPre[warning.type] +
              resourceTypes[warning.type] +
              resourceTypesUnit[warning.type]
            }}您设置的数值时，开始接收提醒。
          </div>
        </div>
      </div>
      <div class="formItem">
        <div class="label">提醒时段</div>
        <div class="vals">
          <div class="timePicker">
            <span class="text">每天</span>
            <vh-time-picker
              v-model="warning.date"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              :disabled="!warning.alertSwitch"
              is-range
              format="HH:mm"
              value-format="HH:mm"
              style="width: 240px"
            ></vh-time-picker>
          </div>
        </div>
      </div>
      <div class="formItem">
        <div class="label">重复发送</div>
        <div class="vals swtich">
          <vh-switch
            class="swtich"
            v-model="warning.repeatSwitch"
            active-color="#FB3A32"
            inactive-color="#CECECE"
            :disabled="!warning.alertSwitch"
            :active-text="repeatSwitchDesc"
          ></vh-switch>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <vh-button size="medium" type="info" plain round @click="earlyWarningVisible = false">
          取消
        </vh-button>
        <vh-button size="medium" type="primary" round @click="saveWarning">确定</vh-button>
      </div>
    </vh-dialog>
  </div>
</template>

<script>
  import versionInfo from '@/components/DataUsage/index';
  import lintCharts from '@/components/Echarts/lineEcharts';
  import { sessionOrLocal } from '@/utils/utils';
  import { formatMoney } from '@/utils/filter';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import regRule from '@/utils/reg-rule';
  export default {
    name: 'financeInfo',
    components: {
      versionInfo,
      lintCharts,
      noData
    },
    data() {
      return {
        lintData: [],
        buttonList: [],
        type: false,
        lineType: 1,
        accountType: 1,
        versionList: [
          {
            label: '主账号',
            value: 1
          },
          {
            label: '主账号+子账号',
            value: 2
          }
        ],
        subject: '',
        lineSearchDate: '',
        accountSearchDate: '',
        trendData: {
          webinar_num: 0,
          total_flow: 0,
          live_flow: 0,
          vod_flow: 0,
          total_duration: 0,
          live_duration: 0,
          vod_duration: 0
        },
        time: '',
        versionType: '',
        trendType: 'other', // 默认查询套餐情况，兼容知学云不要短信功能
        lineParams: {},
        dataParams: {},
        totalNum: 0,
        cmsTotalNum: 0,
        vm: {},
        status: 0,
        tableList: [],
        tableCmsList: [],
        tableColumn: [], // 消费表格
        tableCmsColumn: [], // 消息表格
        smsInfo: null, // 消息对象
        pickerOptions: {
          // disabledDate是一个函数,参数是当前选中的日期值,这个函数需要返回一个Boolean值,
          disabledDate: time => {
            return this.dealDisabledData(time);
          }
        },
        pickerOptionsTwo: {
          // disabledDate是一个函数,参数是当前选中的日期值,这个函数需要返回一个Boolean值,
          disabledDate: time => {
            return this.dealDisabledDataTwo(time);
          }
        },
        earlyWarningVisible: false,
        warning: {
          type: 0,
          value: '',
          phone: '',
          email: '',
          date: null,
          // count: '',
          alertSwitch: false,
          repeatSwitch: false
        },
        resourceTypesPre: ['实时', '剩余', '剩余'],
        resourceTypes: ['并发', '流量', '时长'],
        resourceTypesUnit: ['高于', '低于', '低于'],
        resourceTypesItem: ['', 'G', '分钟'],
        childPremission: {},
        canLoad: false
      };
    },
    computed: {
      showSmsModule: function () {
        const userInfo = JSON.parse(sessionOrLocal.get('userInfo'));
        const isNoticeMessage = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'))[
          'message_notice'
        ];
        // 不是知学云账号 & 开启了 短信通知配置项权限
        return userInfo.user_extends.extends_remark != 1 && isNoticeMessage == 1;
      },
      alertSwitchDesc() {
        return this.warning.alertSwitch
          ? '已开启，请根据实际情况进行预警提醒的设置'
          : '开启后，请根据实际情况进行预警提醒的设置';
      },
      repeatSwitchDesc() {
        return this.warning.repeatSwitch
          ? '已开启，提醒时段内达到设置值，最多发送10次'
          : '开启后，提醒时段内达到设置值，最多发送10次';
      }
    },
    filters: {
      formatMoney
    },
    async created() {
      const userInfo = JSON.parse(sessionOrLocal.get('userInfo'));
      // 子账号
      if (userInfo.parent_id > 0) {
        const res = await this.$fetch('getChildPermission');
        if (res.code == 200) {
          console.log('当前权限', res.data);
          sessionOrLocal.set('SAAS_V3_SON_PS', JSON.stringify(res.data));
          this.childPremission = res.data;
        } else {
          sessionOrLocal.removeItem('SAAS_V3_SON_PS');
          this.childPremission = {};
        }

        // 获取子账号权限，更新
        // const childPremission = JSON.parse(sessionOrLocal.get('SAAS_V3_SON_PS'));
        const permission_fin = !(
          this.childPremission && Number(this.childPremission.permission_fin) === 0
        );
        if (!permission_fin) {
          //无权限
          this.$router.push('/warning/404');
          return;
        }
        this.canLoad = true;
      }
      this.canLoad = true;
      this.parentId = JSON.parse(sessionOrLocal.get('userInfo')).parent_id;
      this.childNum = JSON.parse(sessionOrLocal.get('SAAS_VS_PES', 'localStorage'))[
        'child_num_limit'
      ];
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      this.getVersion();
      if (this.parentId == 0 && this.childNum == 1) {
        this.type = true;
      }
    },
    mounted() {
      this.initPage();
    },
    beforeRouteLeave(to, from, next) {
      if (this.status && this.versionType != 2) {
        this.vm.close();
      }
      next();
    },
    watch: {
      'warning.value'(val) {
        this.warning.value = (val + '').match(regRule.num8)
          ? (val + '').match(regRule.num8)[0]
          : '';
      },
      'warning.phone'(val) {
        this.warning.phone = (val + '').match(regRule.num8)
          ? (val + '').match(regRule.num8)[0]
          : '';
      }
      // 'warning.count'(val) {
      //   this.warning.count = (val + '').match(regRule.num8)
      //     ? (val + '').match(regRule.num8)[0]
      //     : '';
      // }
    },
    methods: {
      dealDisabledData(time) {
        return time.getTime() > Date.now() - 8.64e7; //设置选择今天之前的日期（不能选择当天）
      },
      dealDisabledDataTwo(time) {
        return time.getTime() > Date.now(); //设置选择今天以及今天以前的日期
      },
      initPage() {
        // 初始化设置日期为最近一周
        const end = new Date();
        const start = new Date();
        end.setTime(end.getTime() - 3600 * 1000 * 24);
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        this.lineSearchDate = [dayjs(start).format('YYYY-MM-DD'), dayjs(end).format('YYYY-MM-DD')];
        this.accountSearchDate = [
          dayjs(start).format('YYYY-MM-DD'),
          dayjs(end).format('YYYY-MM-DD')
        ];
      },
      getVersion() {
        this.$fetch('getVersionInfo', { user_id: this.userId })
          .then(res => {
            this.versionType = parseInt(res.data.type);
            if (res.data.type == 2) {
              this.buttonList = res.data.duration.buttons;
            } else {
              this.buttonList = res.data.concurrency
                ? res.data.concurrency.buttons
                : res.data.flow.buttons;
            }
            this.getLineList();
            this.getAccountList();
            // 格式化表格
            this.compareTableColumns();
            this.status = res.data.arrears.total_fee;
            if (this.status) {
              this.initPayMessage(res.data.arrears);
            }
          })
          .catch(e => {
            console.log(e);
          });
      },
      compareTableColumns() {
        const defaultColumns = [
          {
            label: '消费时间',
            key: 'pay_date',
            width: 120
          },
          {
            label: '活动ID',
            key: 'webinar_id',
            width: 120
          },
          {
            label: '活动名称',
            key: 'subject'
          },
          {
            label: '消费类型',
            key: 'typePay',
            width: 120
          },
          {
            label: '账号类型',
            key: 'typeText',
            width: 120
          },
          {
            label: '账号ID',
            key: 'user_id',
            width: 120
          },
          {
            label: '账号昵称',
            key: 'nick_name',
            width: 120
          }
        ];
        if (this.trendType == 'sms') {
          defaultColumns.push({
            label: '短信消耗（条）',
            key: 'sms',
            width: 150
          });
          this.tableCmsColumn = defaultColumns;
        } else {
          this.getColumns(defaultColumns, this.versionType);
        }
      },
      getColumns(defaultColumns, versionType) {
        if (versionType == 1) {
          this.tableColumn = defaultColumns.concat({
            label: '消耗流量（GB）',
            key: 'webinar_flow',
            width: 150
          });
        } else if (versionType == 2) {
          this.tableColumn = defaultColumns.concat({
            label: '消耗时长（分钟）',
            key: 'webinar_duration',
            width: 150
          });
        } else {
          this.tableColumn = defaultColumns.concat({
            label: '最高并发（方）',
            key: 'webinar_max_uv',
            width: 150
          });
        }
      },
      getSearchLineList() {
        this.$vhall_paas_port({
          k: this.lineType == 1 ? 100697 : 100698,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.getLineList();
      },
      // 用量统计数据
      getLineList() {
        let paramsObj = {
          type: this.lineType || 1
        };
        if (this.lineSearchDate) {
          paramsObj['start_time'] = this.lineSearchDate[0];
          paramsObj['end_time'] = this.lineSearchDate[1];
        }
        let obj = Object.assign({}, paramsObj);
        this.lineParams = obj;
        this.getFlowTrend(obj);
      },
      getFlowTrend(obj) {
        let url =
          this.versionType == 1
            ? 'getFlowLineInfo'
            : this.versionType == '2'
            ? 'getTimeLineInfo'
            : 'getTrendLineInfo';
        this.$fetch(url, obj)
          .then(res => {
            this.lintData = res.data.list;
          })
          .catch(e => {
            console.log(e);
          });
      },
      // 获取并发-最高  流量-活动个数
      getOnlinePay(obj) {
        let url =
          this.versionType == 1
            ? 'getFlowPayInfo'
            : this.versionType == '2'
            ? 'getDurationInfo'
            : 'getTrendHighInfo';
        this.$fetch(url, obj)
          .then(res => {
            this.trendData = res.data || {};
          })
          .catch(e => {
            console.log(e);
          });
      },
      getTypeList() {
        this.$vhall_paas_port({
          k: this.accountType == 1 ? 100700 : 100701,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.getAccountList('search');
      },
      getSearchList() {
        if (this.subject) {
          this.$vhall_paas_port({
            k: 100699,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.getAccountList('search');
      },
      // 订单明细
      goAccountDetail() {
        this.$vhall_paas_port({
          k: 100695,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$router.push({
          path: '/finance/infoDetail'
        });
      },
      // 获取消费账单列表
      getAccountList(params) {
        let pageInfo = this.$refs.accountTableList.pageInfo;
        let paramsObj = {
          subject: this.subject,
          type: this.accountType || 1
        };
        if (params == 'search') {
          pageInfo.pos = 0;
          pageInfo.pageNum = 1;
        }
        if (this.accountSearchDate) {
          paramsObj['start_time'] = this.accountSearchDate[0];
          paramsObj['end_time'] = this.accountSearchDate[1];
        } else {
          paramsObj['start_time'] = '';
          paramsObj['end_time'] = '';
        }
        this.dataParams = this.$params(paramsObj);
        let obj = Object.assign({}, pageInfo, paramsObj);

        this.getOnlinePay(this.$params(this.dataParams));
        this.getUserSmsPay(this.$params(this.dataParams));
        // 格式化表格头
        this.compareTableColumns();
        this.$nextTick(() => {
          if (this.trendType == 'sms') {
            // 获取短信消耗明细
            this.getUserSmsPayByPage(this.$params(obj));
          } else {
            // 获取流量消耗明细
            this.getDataList(this.$params(obj));
          }
        });
      },
      // 流量消费等查询
      getDataList(obj) {
        let url =
          this.versionType == 1
            ? 'getBusinessList'
            : this.versionType == 2
            ? 'getDurationList'
            : 'getAccountList';
        this.$fetch(url, obj)
          .then(res => {
            let costList = res.data.list;
            this.totalNum = res.data.total;
            costList.map(item => {
              item.typeText = item.type == 1 ? '主账号' : '子账号';
              item.typePay =
                item.pay_type >= 0 && item.pay_type <= 4
                  ? ['', '并发', '流量', '时长', '短信'][item.pay_type]
                  : '';
            });
            this.tableList = costList;
          })
          .catch(e => {
            console.log(e);
          });
      },
      // 短信消耗总数查询
      getUserSmsPay(obj) {
        this.$fetch('getUserSmsPay', obj)
          .then(res => {
            this.smsInfo = res.data;
          })
          .catch(e => {
            console.log(e);
          });
      },
      // 短信分页查询
      getUserSmsPayByPage(obj) {
        this.$fetch('getUserSmsPayByPage', obj)
          .then(res => {
            let costList = res.data.list;
            this.cmsTotalNum = res.data.total;
            costList.map(item => {
              item.typeText = item.type == 1 ? '主账号' : '子账号';
              item.typePay =
                item.pay_type >= 0 && item.pay_type <= 4
                  ? ['', '并发', '流量', '时长', '短信'][item.pay_type]
                  : '';
            });
            this.tableCmsList = costList;
          })
          .catch(e => {
            console.log(e);
          });
      },
      getOrderArrear() {
        let params = {
          user_id: this.userId,
          type: this.versionType == 1 ? 1 : 2
        };
        this.$fetch('orderArrears', params)
          .then(res => {
            if (res.code == 200) {
              this.$router.push({
                path: '/finance/payOrder',
                query: {
                  userId: this.userId,
                  orderId: res.data.order_id
                }
              });
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || '获取信息失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            console.log(res);
          });
      },
      initPayMessage(info) {
        if (this.versionType == 2) {
          this.$vhMessage({
            showClose: true,
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: '您的时长资源已用尽，请联系专属客服充值',
            type: 'warning'
          });
          return;
        }
        let that = this;
        let flow = info.flow;
        let extendFee = info.extend;
        let total = flow < 0 ? `${flow} G` : `${extendFee} 方`;
        let text = `您有${this.versionType == 1 ? '流量' : '并发'}欠费${
          info.total_fee
        }元未支付(${total})`;
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 0,
          dangerouslyUseHTMLString: true,
          message:
            '<p style="color:#1A1A1A">' +
            text +
            '<span id="openList" style="color:#FA9A32;cursor: pointer;padding-left:10px">请立即支付</span></p>',
          type: 'warning'
        });
        let open = document.querySelector('#openList');
        open.addEventListener('click', function (e) {
          that.vm.close();
          that.getOrderArrear();
        });
      },
      // 导出用量统计
      exportCenterData() {
        let url =
          this.versionType == 1
            ? 'exportFlow'
            : this.versionType == 2
            ? 'exportDuration'
            : 'exportOnline';
        this.$fetch(url, this.lineParams)
          .then(res => {
            this.$vhall_paas_port({
              k: 100696,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: '',
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.$vhMessage({
              message: `${
                this.versionType == 1 ? '流量' : this.versionType == 2 ? '时长' : '并发'
              }用量统计导出申请成功，${
                this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
              }`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            this.$EventBus.$emit('saas_vs_download_change');
          })
          .catch(res => {
            this.$vhMessage({
              message:
                res.msg ||
                `${
                  this.versionType == 1 ? '流量' : this.versionType == 2 ? '时长' : '并发'
                }用量统计导出失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      // 导出消费账单
      exportAccount() {
        let url = '';
        if (this.trendType == 'sms') {
          // 短信导出
          url = 'exportUserSmsPayDetail';
        } else {
          url =
            this.versionType == 1
              ? 'exportFlowDetail'
              : this.versionType == 2
              ? 'exportDurationDetail'
              : 'exportOnlineDetail';
        }
        this.$fetch(url, this.dataParams)
          .then(res => {
            this.$vhall_paas_port({
              k: 100702,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: '',
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.$vhMessage({
              message: `${
                this.trendType == 'sms'
                  ? '短信'
                  : this.versionType == 1
                  ? '流量'
                  : this.versionType == 2
                  ? '时长'
                  : '并发'
              }消费账单导出申请成功，${
                this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
              }`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            this.$EventBus.$emit('saas_vs_download_change');
          })
          .catch(res => {
            this.$vhMessage({
              message:
                res.msg ||
                `${
                  this.trendType == 'sms'
                    ? '短信'
                    : this.versionType == 1
                    ? '流量'
                    : this.versionType == 2
                    ? '时长'
                    : '并发'
                }消费账单导出失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      //预警提醒
      openEarlyWarning() {
        this.getWarning().then(res => {
          console.log(res);
          if (res) {
            this.earlyWarningVisible = true;
          } else {
            this.earlyWarningVisible = false;
          }
        });
      },
      //保存预警设置
      saveWarning() {
        console.log(this.warning);
        let params = {};
        if (this.warning.alertSwitch) {
          if (!this.warning.value) {
            this.$vhMessage.warning('请填写提醒数值');
            return;
          }
          if (!this.warning.phone && !this.warning.email) {
            this.$vhMessage.warning('请填写接收手机或者邮箱');
            return;
          }
          if (this.warning.phone && !regRule.mobile.test(this.warning.phone)) {
            this.$vhMessage.warning('请填写正确的手机');
            return;
          }
          if (this.warning.email && !regRule.email.test(this.warning.email)) {
            this.$vhMessage.warning('请填写正确的邮箱');
            return;
          }
          if (!this.warning.date || this.warning.date[0] == '' || this.warning.date[1] == '') {
            this.$vhMessage.warning('请选择提醒时段');
            return;
          }
          params = {
            nums: this.warning.value,
            receive_phone: this.warning.phone,
            receive_email: this.warning.email,
            begin_time: this.warning.date[0],
            end_time: this.warning.date[1],
            warning_switch: 1,
            repeat_notice: this.warning.repeatSwitch ? 1 : 0
          };
          if (!this.warning.phone) {
            delete params.receive_phone;
          }
          if (!this.warning.email) {
            delete params.receive_email;
          }
          this.$fetch('saveWarningInfo', params)
            .then(res => {
              if (res.code == 200) {
                this.earlyWarningVisible = false;
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || '保存信息失败',
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        } else {
          params = {
            warning_switch: 0
          };
          this.$fetch('setWarningSwitch', params)
            .then(res => {
              if (res.code == 200) {
                this.earlyWarningVisible = false;
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.msg || '保存信息失败',
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        }
      },
      //获取预警设置
      getWarning() {
        return this.$fetch('getWarningInfo')
          .then(res => {
            if (res.code == 200) {
              this.warning.type = res.data.resource_type; //0并发 1流量 2时长
              this.warning.value = res.data.nums; //
              this.warning.phone = res.data.receive_phone; //
              this.warning.email = res.data.receive_email; //
              this.warning.date =
                res.data.begin_time || res.data.end_time
                  ? [res.data.begin_time, res.data.end_time]
                  : null; //
              this.warning.alertSwitch = res.data.warning_switch == 1; //提醒开关：0关闭 1开启
              this.warning.repeatSwitch = res.data.repeat_notice == 1; //是否重复发送：0否   1是 - 最多发送10次
              this.$nextTick(0);
              return true;
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || '获取信息失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            return false;
          });
      }
    }
  };
</script>

<style lang="less" scoped>
  .finance-info {
    .serach-line {
      padding: 24px;
      border-radius: 4px;
      background: #fff;
    }
    .title-data {
      margin: 0 0 20px 0;
      text-align: left;
      line-height: 30px;
      position: relative;
      span {
        font-size: 22px;

        font-weight: 600;
        color: #1a1a1a;
      }
      .detail {
        position: absolute;
        top: 0;
        right: 0;
        color: #262626;
        font-size: 14px;
        cursor: pointer;
        &:last-child {
          right: 80px;
          color: #3b67f9;
        }
      }
    }
    .statistical-line {
      text-align: left;
      position: relative;
      margin-top: 20px;
      span {
        display: inline-block;
        font-size: 16px;
        color: #1a1a1a;
        margin-bottom: 10px;
        padding-bottom: 5px;
      }
      i {
        font-size: 14px;
        padding: 0 2px;
      }
    }
    .content-grid {
      width: 100%;
      height: 100px;
      margin-bottom: 20px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content-item {
        width: 24%;
        background: #f7f7f7;
        height: 100px;
        border-radius: 4px;
      }
      .grid-item {
        width: 49%;
        background: #f7f7f7;
        height: 100px;
        border-radius: 4px;
      }
      &.include_sms {
        .content-item {
          width: 19%;
        }
        .grid-item {
          width: 33%;
        }
      }
      .grid-content {
        margin: 24px 16px;
        text-align: left;
        h1 {
          font-size: 28px;
          color: #1a1a1a;
          line-height: 32px;
          font-weight: bold;
        }
        p {
          font-size: 14px;
          color: #999;
          line-height: 20px;
          display: inline-block;
          i {
            color: #1a1a1a;
          }
        }
      }
    }
    .search-data {
      position: relative;
      padding: 5px 0 20px 0;
      // margin-bottom: 15px;
      &/deep/ .vh-select,
      & > .search-tag {
        margin-left: 12px;
      }
      .export-data {
        position: absolute;
        right: 0;
        top: 7px;
      }
    }
  }
  ::v-deep .saasicon_help_m {
    color: #999999;
  }
</style>
<style lang="less">
  .earlyWarningSet {
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #262626;
    }
    .formItem {
      display: flex;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      .label {
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        min-width: 56px;
        margin-right: 12px;
        line-height: 40px;
      }
      .vals {
        .vh-input-group__append,
        .vh-input-group__prepend {
          padding: 0 10px;
        }
        &.swtich {
          display: flex;
          align-items: center;
        }
        .inputs {
          display: flex;
          align-items: center;
          .setInput {
            width: 180px;
            margin-right: 10px;
          }
        }
        .tip {
          margin-top: 10px;
        }
        .timePicker {
          // margin-bottom: 10px;
        }
        .text {
          margin-right: 10px;
        }
        .count {
          .setInput {
            width: 120px;
          }
        }
      }
    }
  }
</style>
